import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import COLORS from '../constants/colors';

export const Description = styled.div`
  margin: 2rem 1rem;
`;

export const Quote = styled.div`
  border-left: 0.4rem solid #ffd000;
  padding-left: 2rem;
`;

export const Translation = styled.p`
  font-size: 1.2rem;
  color: ${({ theme: { theme } }) => theme.color};
`;

export const Wrapper = styled.div`
  margin: 4rem 0;
  padding: 0 2rem !important;
  width: 100% !important;
  min-height: 65rem;

  /* stylelint-disable */
  ${breakpoint('tablet')` 
    margin: 1rem;
    max-width: 110rem !important;
  `}
  /* stylelint-enable */  

  /* stylelint-disable */
  ${breakpoint('desktop')` 
    margin: 4rem 0 1rem 18.75%;
    width: 80% !important;
  `}
  /* stylelint-enable */  

  h2 {
    margin-top: 5rem;
  }

  p,
  li,
  input {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 3rem;
  }

  .heading {
    background-color: ${COLORS.WHITE};
    padding: 1.4rem 2rem 1.6rem;
    width: 100%;
    z-index: 1;
  }

  i.checkmark.icon,
  i.remove.icon {
    margin-left: 1rem;
    position: absolute;
    right: -4.4rem;
  }
`;

export const H1 = styled.h1`
  font-size: 3rem;
  margin-bottom: 2rem;
`;
