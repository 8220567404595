import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import ReactGA from 'react-ga';
import Header from 'components/page/Header';
import Footer from 'components/page/Footer';
import { GlobalStyle, theme } from 'theme/globalStyle';
import { Wrapper } from 'styles/content';
import 'semantic-ui-css/semantic.min.css';

const ContentWrapper = styled.div`
  width: 100% !important;
  margin-top: 6rem;

  ${breakpoint('mobile')`
     margin-top: 4rem;
  `}
`;

const TRACKING_ID = 'UA-41090420-1';

ReactGA.initialize(TRACKING_ID);

const Layout = ({ children, recentPosts = [] }) => {
  return (
    <ThemeProvider theme={theme}>
      <ContentWrapper>
        <GlobalStyle />
        <Header />
        <Wrapper>{children}</Wrapper>
        <Footer recentPosts={recentPosts} />
      </ContentWrapper>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  posts: PropTypes.array,
};

export default Layout;
