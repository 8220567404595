import React, { useState } from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [query, setQuery] = useState('');

  const handleMenuClick = (evt) => {
    evt.preventDefault();
    console.log('handleMenuClick');
    setShowMenu(!showMenu);
  };

  const handleInputChange = (event) => {
    const query = event.target.value;
    setQuery(query);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    window.location.href = `/search?q=${query}`;
  };

  return (
    <div className="ui page grid">
      <Menu className="computer tablet only row">
        <div className="ui fixed inverted menu">
          <div className="ui container">
            <Link href="/" className="item">
              Home
            </Link>
            <Link href="/category/development/" className="item">
              Development
            </Link>
            <Link href="/category/design/" className="item">
              Design
            </Link>
            <Link href="/category/technology/" className="item">
              Technology
            </Link>
            <Link href="/category/photography/" className="item">
              Photography
            </Link>
            <Link href="/about/" className="item">
              About
            </Link>
            <Search>
              <Input
                type="text"
                id="filter"
                placeholder="Search"
                onChange={handleInputChange}
              />
              <Button type="submit" onClick={handleSubmit}>
                <Icon className="search icon" />
              </Button>
            </Search>
          </div>
        </div>
      </Menu>
      <MobileMenu className="mobile only narrow row">
        <div className="mobile only narrow row">
          <div className="ui inverted navbar menu">
            <div className="right menu open">
              <a href="#" className="menu item" onClick={handleMenuClick}>
                <i className="bars icon"></i>
              </a>
            </div>
          </div>
          {showMenu && (
            <div className="ui vertical navbar menu">
              <HomeLink href="/" className="item home">
                Home
              </HomeLink>
              <DropDownItem href="/category/development/" className="item">
                Development
              </DropDownItem>
              <DropDownItem href="/category/design/" className="item">
                Design
              </DropDownItem>
              <DropDownItem href="/category/technology/" className="item">
                Technology
              </DropDownItem>
              <DropDownItem href="/category/photography/" className="item">
                Photography
              </DropDownItem>
              <DropDownItem href="/about/" className="item">
                About
              </DropDownItem>
            </div>
          )}
        </div>
      </MobileMenu>
    </div>
  );
};

const Menu = styled.div`
  .item {
    font-family: 'Oswald', Arial, Verdana, 'Helvetica Neue', Helvetica,
      sans-serif;
    letter-spacing: 0.36rem;
  }
`;

const HomeLink = styled.a`
  font-size: 1.6rem;
  padding-right: 0 !important;

  .item.home {
    display: inline-block;
    border: 0.1rem solid red;
  }

  ${breakpoint('tablet')`
    padding-left: initial !important;
    padding-right: initial !important;

    .item.home {
      display: hidden;
    }
  `}
`;

const Link = styled.a`
  display: none !important;
  text-transform: uppercase !important;

  ${breakpoint('tablet')`
    display: flex !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;

    .item.home {
      display: hidden;
    }
  `}
`;

const Input = styled.input`
  transition: width 0.5s ease-in-out;
  font-weight: 400;
  color: #8c919b;
  height: 100%;
  background-color: black;
  border: 0.1rem solid black;
  line-height: 22px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: 'Oswald', Arial, Verdana, 'Helvetica Neue', Helvetica, sans-serif;
  letter-spacing: 0.36rem;
  padding-left: 2rem;

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  background: black;
  border: 0;
  height: 100%;
`;

const Icon = styled.i`
  background: black;
  color: white;
`;

const Search = styled.form`
  display: flex;
`;

const DropDownItem = styled.a.attrs({
  className: 'ui simple dropdown item',
})``;

const MobileMenu = styled.div`
  padding-top: 0 !important;
  top: 0;
`;

export default Header;
