import React from 'react';
import styled from 'styled-components';
import { Link as link } from 'gatsby';
import { MANATEE, SUPERNOVA } from 'constants/colors';

const Wrapper = styled.div.attrs({
  className: 'ui inverted vertical segment footer',
})`
  padding: 2rem 3rem !important;
  margin-top: 4rem !important;
  width: 100%;
  font-size: 1.4rem !important;
  text-align: left;

  .header,
  .item {
    text-align: left !important;
  }
`;

const H4 = styled.h4`
  text-transform: uppercase;
  color: ${SUPERNOVA};
  font-family: 'Oswald', Arial, Verdana, 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: 0.2rem;
`;

const List = styled.ol`
  padding-left: 1rem !important;
`;

const Item = styled.li`
  color: ${MANATEE};
  padding-left: 1rem;
  padding-bottom: 1rem;
`;

const Link = styled(link)`
  color: ${MANATEE};

  &:hover {
    color: ${MANATEE};
    text-decoration: underline;
  }
`;

const Credit = styled.div`
  letter-spacing: 0.36rem;
  text-transform: uppercase;
`;

const titleCase = (str) =>
  str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());

const Footer = ({ recentPosts }) => (
  <Wrapper>
    <div className="ui container">
      <div className="ui stackable inverted divided grid">
        <div className="wide column">
          {recentPosts.length > 0 && <H4>Recent Posts</H4>}
          <List>
            {recentPosts.map(({ node }, index) => (
              <Item key={index}>
                <span>
                  <Link to={node.frontmatter.path}>
                    {titleCase(node.frontmatter.title)}
                  </Link>
                </span>
                <br />
                <small>{node.frontmatter.date}</small>
              </Item>
            ))}
          </List>
        </div>
      </div>
      <div className="ui inverted section divider" />
      <Credit className="ui horizontal">Created by Sean Amarasinghe</Credit>
    </div>
  </Wrapper>
);

export default Footer;
