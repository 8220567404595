import React from 'react';
import PropTypes from 'prop-types';

const JsBin = ({ href }) => (
  <a class="jsbin-embed" href={href}>
    JS Bin on jsbin.com
  </a>
);

JsBin.propTypes = {
  href: PropTypes.string.isRequired,
};

export default JsBin;
