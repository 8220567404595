export const ALABASTER = '#f9f9f9';
export const ALUMINIUM = '#979797';
export const BLACK = '#000';
export const CORNFLOWER_BLUE = '#5bc0de';
export const DARK_JUNGLE_GREEN = '#1b1c1d';
export const GALLERY = '#eee';
export const HARLEQUIN = '#39ff14';
export const KEPPEL = '#36acaa';
export const PERSIAN_ROSE = '#ff1d8e';
export const MANATEE = '#8c919b';
export const MAROON_FLUSH = '#c7254e';
export const RADICAL_RED = '#ff435f';
export const REVOLVER = '#3A1C46';
export const SANDY_BROWN = '#f0ad4e';
export const SIREN = '#7f0055';
export const SUPERNOVA = '#ffcc0d';
export const SWIRL = '#d4d0c8';
export const TANGERINE = '#fed001';
export const WHITE = '#fff';
export const ZIRCON = '#e0e1e2';

export default {
  ALABASTER,
  ALUMINIUM,
  BLACK,
  CORNFLOWER_BLUE,
  DARK_JUNGLE_GREEN,
  HARLEQUIN,
  MANATEE,
  MAROON_FLUSH,
  PERSIAN_ROSE,
  RADICAL_RED,
  REVOLVER,
  SANDY_BROWN,
  SIREN,
  SUPERNOVA,
  SWIRL,
  TANGERINE,
  ZIRCON,
  WHITE,
};
