import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { switchProp } from 'styled-tools';
import Markdown from 'markdown-to-jsx';

import {
  CORNFLOWER_BLUE,
  GALLERY,
  MAROON_FLUSH,
  SANDY_BROWN,
} from 'constants/colors';

const colors = {
  info: CORNFLOWER_BLUE,
  warn: SANDY_BROWN,
  error: MAROON_FLUSH,
};

const Wrapper = styled.div`
  border: 0.1rem solid ${GALLERY};
  border-left-width: 0.5rem;
  border-left-style: solid;
  border-left-color: ${switchProp('type', colors)};
  padding: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Title = styled.h5`
  font-size: 2rem;
  color: ${switchProp('type', colors)};
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
`;

const Message = ({ type, title, content }) => (
  <Wrapper type={type}>
    {title && <Title type={type}>{title}</Title>}
    <p>
      <Markdown>{content}</Markdown>
    </p>
  </Wrapper>
);

Message.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
};

export default Message;
