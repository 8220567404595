import { createGlobalStyle } from 'styled-components';
import {
  ALABASTER,
  DARK_JUNGLE_GREEN,
  KEPPEL,
  REVOLVER,
  SIREN,
  SWIRL,
  WHITE,
} from '../constants/colors';

export const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

    :root {
      font-size: 10px;
    }

    html {
      font-family: 'Helvetica Neue', Helvetica, sans-serif !important;
    }

    li {
      line-height: 2;
    }

    table {
      background: ${ALABASTER};
      padding: 1rem;
    }

    th, td {
      font-size: 1.6rem !important;
    }

    ::selection {
      background-color: #ffcc0d !important;
    }

    /* Semantic UI */

    figure {
      margin: 0 !important;
    }

    .ui.celled.table,
    .ui.table {
      font-size: 1.4rem;
    }

    .ui.table thead th {
      text-align: center;
    }

    .ui.table.example {
      font-weight: normal;
    }

    .ui.header {
      background: black !important;
      color: black !important;
    }

    .ui.menu {
      font-size: 1.6rem !important;
      position:relative;
    }

    .ui.vertical.tabular.menu .active.item {
      background: black !important;
      border: 0;
      color: black !important;
    }

    .mobile.only.narrow.row {
      position: fixed;
      width: 100vw;
      z-index: 3;
    }

    .footer .ui.inverted.header {
      background: ${REVOLVER} !important;
      color: ${WHITE} !important;
    }

    .ui.inverted.menu {
      background: ${REVOLVER} !important;
    }

    .ui.inverted.segment {
      background: ${REVOLVER} !important;
    }

    .ui.inverted.navbar.menu {
      width: 104vw;
    }

    .ui.vertical.navbar.menu {
      position: fixed;
      top: 4rem;
      width: 104vw;
      z-index: 5;
    }

    .ui.vertical.menu {
      position: absolute;
    }

    .ui.vertical.menu .dropdown.item:not(.upward) .menu {
      left: -1rem;
      padding-left: 0.6rem;
      position: relative;
      top: 1.5rem!important;
      width: 104vw;
    }

    /* Gatsby Markup */
    h2 {
        font-size: 2.4rem !important;
    }

    h3 {
      font-size: 2rem !important;
    }

    h4 {
      font-size: 1.6rem !important;
    }

    p {
      padding: 1rem 0 !important;
      line-height: 2;
      margin-bottom: 0 !important;
    }

    /* Prism */
    .prism-code {
      font-size: 1.6rem !important;
      margin: 2rem 0 !important;
    }

    .prism-code.language-sh,
    .prism-code.language-sh-pr  {
      background-color: #543729 !important;
      margin-bottom: 2rem;
      border-radius: .5rem;
      letter-spacing: .4px;
      padding: 2rem;
    }

    .prism-code.language-sh .token.plain,
    .prism-code.language-sh-pr .token.plain {
      color: #fff !important;
    }

    .prism-code.language-sh-pr .token-line [class^=gatsby-browser__LineNo-]{
      border: 0 !important;
      width: 0 !important;
    }

    .prism-code.language-sh .token.plain:before {
      content: "$ ";
      color: #A9D866;
    }

    .prism-code.language-sh-pr .token-line:first-child:before {
      content: "$ ";
      color: #A9D866;
    }

    .prism-code.language-sh-pr .token.plain {
      margin-left: -2.4rem;
    }

    .prism-code {
      background-color: ${ALABASTER} !important;
      margin: 0;
      padding: 1.6rem 1rem;
    }

    .token-line {
      line-height: 1.2;
    }

    .token.keyword {
      color: ${SIREN} !important;
    }

    .token.string {
      color: ${KEPPEL} !important;
    }

    .token.maybe-class-name {

    }

    code {
      color: #c7254e;
    }

    [class^="gatsby-browser__LineNo"] {
      border-right: 0.3rem solid ${SWIRL} !important;
      text-align: right;
      padding-right: 1rem;
    }

    .prism-code.language-sh [class^="gatsby-browser__LineNo"] {
      border-right: 0 !important;
      width: 0 !important;
    }

    .prism-code.language-sh [class^="gatsby-browser__Space"] {
      padding-left: 0 !important;
    }

    [class^="BlogPost__Excerpt-"]:first-letter {
      font-family: 'Oswald', Arial, Verdana, 'Helvetica Neue', Helvetica, sans-serif;
      font-size: 7rem !important;
      float: left;
      padding: 0.4rem 1.2rem 0 0;
      line-height: 6rem;
      text-transform: uppercase;
    }
`;

export const theme = {
  background: WHITE,
  color: DARK_JUNGLE_GREEN,
  name: 'light',
};
