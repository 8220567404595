import React from 'react';
import PropTypes from 'prop-types';

const Figure = ({ src, alt, figcaption, width }) => (
  <figure>
    <img src={src} alt={alt} width={width} />
    <figcaption>{figcaption}</figcaption>
  </figure>
);

Figure.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.number,
  figcaption: PropTypes.string.isRequired,
};

export default Figure;
